import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import { IndexTemplate } from "../templates/index-Template.js"

const Home = ({data}) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexTemplate
      navbar={ frontmatter.navbar }
      banner = { frontmatter.banner }
      intro = {frontmatter.intro}
      qualities = { frontmatter.qualities }
      nebenkosten = { frontmatter.nebenkosten }
      mindestangaben = { frontmatter.mindestangaben }
      umlageschluessel = { frontmatter.umlageschluessel }
      erstgespraech = { frontmatter.erstgespraech }
      pagebreak = { frontmatter.pagebreak }
      faq = { frontmatter.faq }
      contact = { frontmatter.contact }
      buttonmenu = { frontmatter.buttonmenu }
    />
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Home

export const pageQuery = graphql`
  query IndexTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" }}) {
      frontmatter {
          banner {
            header
            secondheader
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1400,
                  
                )
              }
            }
            subheader
            subtext
          }
          intro {
            header
            text
          }
          qualities {
            text
            image {
              childImageSharp {
                gatsbyImageData (
                  width: 800,
                  placeholder: DOMINANT_COLOR,
                )
              }
            }
          }
          nebenkosten {
            header
            subheader
            list {
              text
            }
          }
          mindestangaben {
            header
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 900,
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
          umlageschluessel {
            header
            list {
              text
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 256,
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            text
          }
          erstgespraech {
            header
            list {
              header
              text
            }
            text
          }
          pagebreak {
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  height:400,
                  width: 2400,
                  quality:70,
                )
              }
            }
          }
          faq {
            header
            fragen {
              title
              text
            }
            button {
              text
            }
          }
          contact {
            title
            subtitle
            email {
              title
              mail
            }
            location {
              title
              street
              place
            }
          }
          buttonmenu {
            subject
            text
            link
          }
      }
    }
  }
`