import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FaqSection from './FaqSection'

const Container = styled.div`
position:relative;
& h1{
    font-size:24px;
    font-weight:400;
    text-align:center;
}
& button{
    display:${props => props.showAll==1?('none'):('block')};
    position:relative;
    text-decoration:none;
    width:200px;
    border:2px solid var(--tmain);
    background-color:var(--tmain);
    padding:6px 24px;
    border-radius:5px;
    color:white;
    font-weight:700;
    text-align:center;
    margin:auto;
    font-size:18px;
    transition: 0.2s ease;
    cursor:pointer;
    transition:background-color .3s ease, color .3s ease;
    
    &:hover {
        background-color:rgba(0,0,0,0);
        color: var(--tmain);
    }
}
@media(min-width:993px){
    & h1{
        font-size:36px;
    }
    & button{
        font-size:18px;
    }
}
`

const QuestionContainer = styled.div`
& >div:nth-child(${props => props.showAll==1?('n'):('-n+4')}){
    display:block;
}
padding-bottom:50px;
`

class Faq extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            showAll: false,
        }
    }

    toggleShowAll(){
        this.setState({showAll: !this.state.showAll});
    }

    render(){

        return(
            <Container id={this.props.id} showAll={this.state.showAll?(1):(0)}>
                <h1>{this.props.data.header}</h1>
                <QuestionContainer showAll={this.state.showAll?(1):(0)}>
                {
                    this.props.data.fragen.map((item, i) => (
                        <FaqSection key={i} expanded={i==0?(true):(false)} data={item}/> 
                    ))
                }
                </QuestionContainer>
                <button onClick={this.toggleShowAll.bind(this)}>{this.props.data.button.text}</button>
            </Container>
        )
    }
}

Faq.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        header: PropTypes.string,
        fragen: PropTypes.object,
        button: PropTypes.shape({
            text: PropTypes.string,
        })
    })
}

export default Faq