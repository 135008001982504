import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PreviewCompatibleImage = ({ imageInfo, loading, objectFit, objectPosition }) => {
  const { childImageSharp, image } = imageInfo
  const loadingData = (loading)?(loading):("lazy")
  const objectFitData = (objectFit)?(objectFit):("cover")
  const objectPositionData = (objectPosition)?(objectPosition):("50% 50%")
  
  if (!!image && !!image.childImageSharp) {
    return (
      <GatsbyImage image={getImage(image)} alt="" loading={loadingData} objectFit={objectFitData} objectPosition={objectPositionData}/>
    )
  }

  if (!!childImageSharp) {
    return <GatsbyImage image={getImage(childImageSharp)} alt="" loading={loadingData} objectFit={objectFitData} objectPosition={objectPositionData}/>
  }

  if (!!image && typeof image === 'string' )
    return <img src={image} alt="" style={{objectFit: objectFitData}}/>

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    loading: PropTypes.string,
    objectFit: PropTypes.string,
    objectPosition: PropTypes.string,
  }).isRequired,
}

export default PreviewCompatibleImage