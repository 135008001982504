import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import ContactButtonTopBanner from './ContactButtonTopBanner'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Diagonal from '../data/icons/diagonal.svg'

const Container = styled.div`
position:relative;
background-color:var(--tsec);
color:var(--twhite);
`
const TextBlock = styled.div`
position:relative;
padding:30px;
width:100%;
text-align:left;
z-index:4;
font-size:16px;
& li {
    text-align:left;
}
&>div:first-child {
    padding-bottom:10vw;
}
&>div:last-child {
    text-align:center;
    padding-bottom:50px;
}
@media(min-width:993px) {
    position:relative;
    width:43%;
    text-align:justify;
    
    &>div:last-child {
        width:80%;
        
    }
}
`
const HeaderText = styled.div`
&>h2 {
    text-align:center;
    font-size:24px;
}
& .secondheader {
    text-align:center;
    font-size:16px;
    position:relative;
    top:-16px;
    font-family:Open Sans;
    font-weight:300;
}
padding-bottom:4px;

@media(min-width:993px) {
    &>h2 {
        font-size:42px;
        text-align:left;
        margin:0;
    }
    & .secondheader {
        text-align:left;
        font-size:20px;
        top:-23px;
    } 
    
}  

`
const Image = styled.div `
position:relative;
width:100%;
height:200px;
z-index:1;
& >* {
    position:relative;
    height:100%;
    width:100%;
}
@media(min-width:993px) {
    width:60%;
    position:absolute;
    height:100%;
    right:0px;
}
`
const Slide = styled.div`
& svg {
    display:none;
}
@media(min-width:993px) {
    min-height:600px;
    & > svg {
        display:block;
        z-index:1;
        height:100%;
        width:auto;
        top:0;
        left:40%;
        position:absolute;
    }
}
`
class Banner extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isExtended:false,
        }
    }
    render() {
        return(
            <Container id={this.props.id}>
                <Slide>
                    <Image>
                        <PreviewCompatibleImage imageInfo={this.props.data} loading={"eager"}/>
                    </Image>
                    <TextBlock>
                        <div>
                            <HeaderText>
                                <h2>{this.props.data.header}</h2>
                                <p className="secondheader">{this.props.data.secondheader}</p>
                            </HeaderText>
                            <ReactMarkdown>{this.props.data.text}</ReactMarkdown>
                        </div>
                        <div>
                            <h3>{this.props.data.subheader}</h3>
                            <ReactMarkdown>{this.props.data.subtext}</ReactMarkdown>
                            <ContactButtonTopBanner>kontaktieren</ContactButtonTopBanner>
                        </div>
                    </TextBlock>
                    <Diagonal/>
                </Slide>
            </Container>
        )
    }
}


Banner.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        header: PropTypes.string,
        secondheader: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        subheader: PropTypes.string,
        text: PropTypes.string,
    })
}

export default Banner