import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import Intro from '../components/Intro'
import UmlageNebenkosten from '../components/UmlageNebenkosten'
import RPicture from '../components/RPicture'
import Qualities from '../components/Qualities'
import IconsAboveText from '../components/IconsAboveText'
import StepPlan from '../components/StepPlan'
import Faq from '../components/Faq'
import Pagebreak from '../components/Pagebreak'
import ButtonMenu from '../components/ButtonMenu'
import Contact from '../components/Contact'
import Spacer from '../components/Spacer'


const BlogpostSection = styled.div`
max-width:1200px;
width:90%;
margin:100px auto;
`

export const IndexTemplate = ({
    banner,
    intro,
    qualities,
    nebenkosten,
    mindestangaben,
    umlageschluessel,
    erstgespraech,
    pagebreak,
    faq,
    contact,
    buttonmenu,
}) => (
    <div>
        <Helmet>
            <meta/>
            <title>Amber Conicor - Nebenkostenabrechnung und Unternehmensberatung - Kirchen und Betzdorf</title>
            <meta name="keywords" content="Betriebskostenabrechnung, Nebenkostenabrechnung, Hausabrechnung, Heizkostenabrechnung, Hausverwaltung, Unternehmensberatung, Siegen, Betzdorf, Altenkirchen, Haiger, Dillenburg, Herborn, Lahn-Dill-Kreis, Neuwied, Andree"/>
            <meta name="description" content="Amber Conicor GmbH bietet im Bereich Betzdorf und Kirchen Leistungen mit Schwerpunkt Nebenkostenabrechnung, Unternehmensberatung im Bereich Sanierung, Unternehmensnachfolge, Logistik, sowie Hausverwaltung und Gebäudeabrechnung."/>
            <html lang="de"/>
        </Helmet> 
        <Layout>
            <Banner id="start" data={banner}/>
            <Spacer height={200}/>
            <Intro id="ueberuns" data = {intro}/>
            <Spacer height={200}/>
            <Qualities data= { qualities }/>
            <Spacer height={200}/>
            <UmlageNebenkosten id="nebenkostenabrechnung" data = {nebenkosten}/>
            <Spacer height={200}/>
            <RPicture data = {mindestangaben}/>
            <Spacer height={200}/>
            <IconsAboveText data={umlageschluessel}/>
            <Spacer height={200}/>
            <StepPlan data = {erstgespraech}/>
            <Spacer height={200}/>
            <Pagebreak data={pagebreak}/>
            <Spacer height={200}/>
            <Faq id="faq" data={faq}/>
            <Spacer height={150}/>
            <Contact id="kontakt" data={contact}/>
            <Spacer height={150}/>
            <ButtonMenu data={buttonmenu}/>            
        </Layout>
    </div>
)

IndexTemplate.propTypes = {
    banner: PropTypes.object,
    intro: PropTypes.object,
    qualities: PropTypes.object,
    nebenkosten: PropTypes.object,
    mindestangaben: PropTypes.object,
    umlageschluessel: PropTypes.object,
    erstgespraech: PropTypes.object,
    pagebreak: PropTypes.object,
    faq: PropTypes.object,
    contact: PropTypes.object,
    buttonmenu: PropTypes.object,
}