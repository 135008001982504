import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SmallHeader from './SmallHeader';
import ListNode from '../data/icons/listNode.svg'

const Container = styled.div`
& h1{
    text-align:center;
    margin:0;
    word-break:break-all;
    font-size:24px;
    font-family:Open Sans;
    font-weight:400;
}
& button{
    display:${props => props.isexpanded==1?('none'):('block')};
	border:none;
	font:inherit;
    background-color:var(--tmain);
    color:var(--twhite);
    border:solid 1.5px var(--tmain);
    padding:6px 24px;
    border-radius:8px;
    position:relative;
    top:0;
    left:50%;
    transform: translateX(-50%);
    font-size:18px;
    &:hover{
        cursor:pointer;
        background-color:var(--twhite);
        color:var(--tmain);
    }
}
@media(min-width:993px){
    & h1{
        font-size:36px;
    }
    & button{
        font-size:20px;
        display:none;
    }
}
`

const ListContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
flex-shrink:0;
align-items:center;
width:90%;
max-width:1250px;
margin:40px auto;
& >div:nth-child(${props => props.isexpanded==1?('n'):('-n+8')}){
    display:flex;
}
@media(min-width:993px){
    flex-flow:row wrap;
    & >div{
        display:flex;
    }
}
`

const ListElement = styled.div`
display:none;
flex-direction:row;
align-items:center;
width:100%;
& svg{
    flex-shrink:0;
    width:32px;
    height:32px;
    margin:6px 12px;
}
@media(min-width:993px){
    width:50%;
}
`

const UmlageNebenkosten = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        console.log(isExpanded);
    }, [isExpanded]);

    return(
        <Container id={props.id} isexpanded={isExpanded?(1):(0)}>
            <h1>{props.data.header}</h1>
            <SmallHeader>{props.data.subheader}</SmallHeader>
            <ListContainer isexpanded={isExpanded?(1):(0)}>
            {
                props.data.list.map((item, i) => (
                    <ListElement key={i}>
                        <ListNode/>
                        <p>{item.text}</p>
                    </ListElement>
                ))
            }
            </ListContainer>
            <button onClick={() => setIsExpanded(!isExpanded)}>alle anzeigen</button>
        </Container>
    );
};

UmlageNebenkosten.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        header: PropTypes.string,
        subheader: PropTypes.string,
        list: PropTypes.shape({
            text: PropTypes.string,
        }),
    }),
}

export default UmlageNebenkosten;