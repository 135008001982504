import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SmallHeader from './SmallHeader'
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
margin:auto;
width:100%;
padding:4%;
max-width:1400px;
& >div {
    margin-top:100px;
}
& >p {
    text-align:center;
    padding-top:60px;
    margin:auto;
    max-width:900px;
    padding-bottom:40px;
    @media(min-width:993px) {
        text-align:justify;
    }
}
`

const Element = styled.div`
position:relative;
&>div {
    position:relative;
    width:100%;
    height:50px;
    color:var(--tmain-light);
    font-size:26px;
    &>h3 {
        display:flex;
        justify-content:center;
        align-items:center;
        height:50px;
        width:50px;
        border-radius:100px;
        border: 2px solid var(--tmain-light);
        background-color:white;
    }
}
&:nth-child(2n-1) {
    @media(min-width:993px) {
        width: calc(50% + 25px);
        &>h3, >p {
            padding-right:50px;
        }
    }
    
    & div {
        height:50px;
        width:100%;
        & h3{
            position:absolute;
            left:0px;
            top:-30px;
         }
        &:after {
            z-index:-1;
            content:"";
            position:absolute;
            top:50%;;
            left:0px;
            height:2px;
            width:100%;
            background-color:var(--tmain-light);
        }
        &:before {
            content:"";
            position:absolute;
            top:16px;
            right:0%;
            height:16px;
            width:16px;
            background-color:white;
            border-radius:100px;
            border: 2px solid var(--tmain-light);
        }
        @media(min-width:993px) {
            & h3 {
                right:0px;
                left:auto;
            }
            &:after {
                width:100%;
            }
            &:before {
                left:0px;
            }
            
        }
    }
}
&:nth-child(2n) {
    @media(min-width:993px) {
        margin-left:calc(50% - 25px);
        width: calc(50% + 25px);
        &>h3, >p {
            padding-left:50px;
        }
    }
    div{
        width:100%;
        & h3{
            &:after {
                z-index:-1;
                content:"";
                position:absolute;
                top:50%;;
                left:0px;
                height:2px;
                width:60%;
                background-color:var(--tmain-light);
            }
            &:before {
                content:"";
                position:absolute;
                top:16px;
                left:60%;
                height:16px;
                width:16px;
                background-color:white;
                border-radius:100px;
                border: 2px solid var(--tmain-light);
            }
        }
        @media(min-width:993px) {
            position:relative;
            & h3 {
                left:50%;
                &:after {
                    width:60%;
                }
                &:before {
                    left:auto;
                    right:40%;
                }
            }
            
        }
    }
}
`

const MarkdownContainer = styled.div`
width:80%;
margin:0 auto;
`

const StepPlan = ({data}) => (
    <Container>
        <SmallHeader>{data.header}</SmallHeader>
        <div>
        {
            data.list.map((Item,i) =>
            <Element key={i}>
                <div>
                    <h3>{i+1}</h3>
                </div>
                <h3>{Item.header}</h3>
                <ReactMarkdown>{Item.text}</ReactMarkdown>
            </Element>
            )
        }
        </div>
        <MarkdownContainer>
            <ReactMarkdown>{data.text}</ReactMarkdown>
        </MarkdownContainer>
    </Container>
)

StepPlan.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.shape({
            header:PropTypes.string,
            text: PropTypes.string,
        })),
        text: PropTypes.string,
    })
}

export default StepPlan