import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SmallHeader from './SmallHeader'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
position:relative;
margin:auto;
padding:4%;
width:100%;
max-width:900px;
`
const TextHolder = styled(ReactMarkdown)`
text-align:center;
@media(min-width:993px) {
    text-align:justify;
}
& strong {
    font-weight:600;
}
`
const IconHolder = styled.div`
max-width: 700px;
margin:auto;
padding-top:50px;
padding-bottom:10px;
display:flex;
justify-content:space-evenly;
align-items:flex-start;
`
const Image = styled.div `
position:relative;
display:flex;
flex-direction:column;
& p {
    text-align:center;
    color:var(--tmain-light);
    font-weight:600;
    font-size:14px;
    @media(min-width:993px) {
        font-size:18px;
    }
}
flex-basis:23%;
& >img {
    margin:auto;
    position:relative;
    height:70%;
    width:70%;
}
`
const IconsAboveText = ({data}) => (
    <Container>
        <SmallHeader>{data.header}</SmallHeader>
        <div>
            <IconHolder>
                {
                    data.list.map((Item, i)=>
                <Image key={i}>
                    {console.log(Item)}
                    {<PreviewCompatibleImage imageInfo={Item}></PreviewCompatibleImage>
                    }
                    <p>{Item.text}</p>
                </Image>
                    )
                }
            </IconHolder>
            <TextHolder>{data.text}</TextHolder>
        </div>
        
        
    </Container>
)

IconsAboveText.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        })),
        text: PropTypes.string,
    })
}

export default IconsAboveText