import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.a`
position:relative;
display:block;
text-decoration:none;
width:200px;
border:2px solid var(--tmain);
background-color:var(--tmain);
padding:6px 24px;
border-radius:5px;
color:white;
font-weight:700;
text-align:center;
margin:35px auto 0 auto;
font-size:18px;
transition: 0.2s ease;
cursor:pointer;
transition:background-color .3s ease, color .3s ease, border .3s ease;
&:hover {
    background-color:var(--twhite);
    border:2px solid var(--twhite);
    color:var(--tmain);
}
`

const ContactButtonTopBanner = ({children}) => (
    <Container href="mailto:bubelgame@gmail.de">
        {children}
    </Container>
)


export default ContactButtonTopBanner