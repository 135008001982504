import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Container = styled.div`
position:relative;
height:300px;
width:100%;
&>div:first-child {
    padding:4%;
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    &>p {
        text-align:center;
        font-size:36px;
        color:var(--twhite);
        font-weight:bold;
    }
    
}

@media(min-width:993px){
    &>div:first-child {
        &>p {
            font-size:48px;
        }
    }
}
`
const Image = styled.div `
position:relative;
width:100%;
height:100%;
z-index:-1;
filter:brightness(0.7);
& >* {
    position:relative;
    height:100%;
    width:100%;
}
`
const Pagebreak = ({data}) => (
    <Container>
        <div>
            <p>{data.text}</p>
        </div>
        <Image>
            <PreviewCompatibleImage imageInfo={data}/>
        </Image>
    </Container>
)

Pagebreak.propTypes = {
    data: PropTypes.shape({
        text: PropTypes.string,
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
    })
}

export default Pagebreak