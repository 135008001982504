import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
display:flex;
flex-flow:column;
justify-content:space-between;
padding:0px 4%;
margin:auto;
max-width:1500px;

@media(min-width:993px) {
    flex-flow:row wrap;
}
`

const Header = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
flex-basis:36%;

& h2 {
    font-weight:300;
    font-size:32px;
}

@media(min-width:993px) {
    & h2 {
        font-size:48px;
    }
}
`

const Text = styled.div`
flex-basis:54%;

& p {
    font-size:18px;
    text-align:center;
    font-weight:300;
    @media(min-width:993px) {
        text-align:left;
    }
}

& * {
    font-size:18px;
    font-weight:300;
}
`

const Intro = (props) => (
    <Container id={props.id}>
        <Header>
            <h2>{props.data.header}</h2>
        </Header>
        <Text>
            <ReactMarkdown>{props.data.text}</ReactMarkdown>
        </Text>
        
    </Container>
)

Intro.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
    })
}

export default Intro