import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BlueArrow from '../data/icons/blueArrow.svg'

const Container = styled.div`
position:relative;
display:none;
width:90%;
margin:30px auto;
& >p{
    display:${props => props.expanded==1?('block'):('none')};
    font-weight:300;
    margin:40px 15px 0 15px;
}
@media(min-width:993px){
    width:55%;
}
`

const HeadContainer = styled.div`
position:relative;
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
& p{
    flex-basis: calc(100% - 40px);
    flex-shring:0;
    font-weight:600;
    font-size:16px;
}
& svg{
    flex-basis:40px;
    flex-shring:2;
    height:40px;
    width:40px;
    transform:rotate(${props => props.expanded==1?('180deg'):('0')});
    transition:transform 0.4s ease;
    &:hover{
        cursor:pointer;
    }
}
&:after{
    position:absolute;
    content:'';
    left:0;
    bottom:-5px;
    height:1px;
    width:100%;
    background-color:var(--tblack);
    border-radius:200px;
}
@media(min-width:993px){
    & p{
        font-size:18px;
    }
}
`

class FaqSection extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            expanded: this.props.expanded!=null ? (this.props.expanded):(false),
        }
    }

    toggleExpansion(){
        this.setState({expanded: !this.state.expanded});
    }

    render(){

        return(
            <Container expanded={this.state.expanded?(1):(0)}>
                <HeadContainer expanded={this.state.expanded?(1):(0)}>
                    <p>{this.props.data.title}</p>
                    <BlueArrow onClick={this.toggleExpansion.bind(this)}/>
                </HeadContainer>
                <p>{this.props.data.text}</p>
            </Container>
        )
    }
}

FaqSection.propTypes = {
    expanded: PropTypes.bool,
    data: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                text: PropTypes.string,
            }),
        ),
}

export default FaqSection