import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
display:flex;
flex-direction:column;
align-items:center;
text-align:center;
margin:0 auto;
@media(min-width:993px){
    width:80%;
    flex-direction:row;
    text-align:left;
}
`

const TitleContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
& h2{
    font-size:36px;
    font-weight:400;
    margin:0;
}
& p{
    font-size:18px;
    margin:0;
}
@media(min-width:993px){
    width:66%;
    & h2{
        font-size:54px;
        margin:0;
    }
    
    & p{
        font-size:20px;
        margin:0;
    }
}
`

const InformationContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
& >div{
    & >div{
        margin:35px 0;
    }
}
& h3{
    font-size:18px;
    font-weight:600;
    margin:0;
}
& p{
    font-size:18px;
    margin:0;
}
@media(min-width:993px){
    width:34%;
    & h3{
        font-size:20px;
        margin:0;
    }
    
    & p{
        font-size:20px;
        margin:0;
    }
    
}
`

const Contact = (props) => (
    <Container id={props.id}>
        <TitleContainer>
            <div>
                <h2>{props.data.title}</h2>
                <p>{props.data.subtitle}</p>
            </div>
        </TitleContainer>
        <InformationContainer>
            <div>
                <div>
                    <h3>{props.data.email.title}</h3>
                    <p>{props.data.email.mail}</p>
                </div>
                <div>
                    <h3>{props.data.location.title}</h3>
                    <p>{props.data.location.street}</p>
                    <p>{props.data.location.place}</p>
                </div>
            </div>
        </InformationContainer>
    </Container>
)

Contact.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        email: PropTypes.shape({
            title: PropTypes.string,
            mail: PropTypes.string,
        }),
        location: PropTypes.shape({
            title: PropTypes.string,
            street: PropTypes.string,
            place: PropTypes.string,
        })
    })
}

export default Contact