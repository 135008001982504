import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.h2`
position:relative;
margin:0;
font-weight:300;
text-align:center;
font-size:20px;
@media(min-width:601) {
    font-size:26px;
}
&:after {
    content:"";
    position:absolute;
    bottom:-10px;
    left:calc(50% - 40px);
    width:80px;
    height:2px;
    background-color:var(--tmain-light);
}
`

const SmallHeader = ({children}) => (
    <Container>
        {children}
    </Container>
)


export default SmallHeader