import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import PreviewCompatibleImage from './PreviewCompatibleImage'

const Container = styled.div`
margin:auto;
display:flex;
flex-direction:column;
width:100%;
@media(min-width:993px) {
    flex-direction:row;
}
`
const Element = styled.div`
position:relative;
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:12vh;
& h3 {
    text-align:center;
    color:var(--twhite);
    font-family:Open Sans;
    font-size:24px;
    font-weight:400;
    filter: drop-shadow(0px 0px 20px #000);
}
@media(min-width:993px) {
    height:240px;
    width:34%;
    & h3{
        font-size:32px;
    }
}
`
const Image = styled.div `
position:absolute;
width:100%;
height:100%;
z-index:-1;
& img {
    filter:brightness(60%);
}
& >* {
    position:relative;
    height:100%;
    width:100%;
}
`


const Qualities = ({data}) => (
    <Container>
        {
            data.map((Item,i) =>
            <Element key={i}>
                <Image>
                    <PreviewCompatibleImage imageInfo={Item} objectPosition="50% 50%"/>
                </Image>
                <h3>{Item.text}</h3>
            </Element>
            )
        }
        
    </Container>
)

Qualities.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        item: PropTypes.shape({
            text: PropTypes.string,
            image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        })
    }))
}

export default Qualities