import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SmallHeader from './SmallHeader'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'

const Container = styled.div`
position:relative;
padding:4%;
width:100%;
max-width:1400px;
margin:auto;
& >div {
    margin-top:160px;
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    & >* {
        flex-basis:46%;
        
    }
    & >*:last-child {
        order:1;
        margin-bottom:20px;
    }
    & >*:first-child {
        order:2;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        text-align:center;
        & ul {
            text-align:left;
            
        }
        & strong {
            font-weight:600;
            line-height:28px;
        }
    }
    @media(min-width:993px) {
        flex-direction:row;
        & >*:last-child {
            order:2;
        }
        & >*:first-child {
            order:1;
            text-align:left;
        }
    }
}
`
const Image = styled.div `
position:relative;
width:100%;
height:100%;
& >* {
    position:relative;
    height:100%;
    width:100%;
    border-radius:5px;
    @media(max-width:993px) {
        max-height:300px;
    }
    
}
`
const RPicture = ({data}) => (
    <Container>
        <SmallHeader>{data.header}</SmallHeader>
        <div>
            <div>
                <ReactMarkdown>{data.text}</ReactMarkdown>
            </div>
            
            <Image>
                <PreviewCompatibleImage imageInfo={data}/>
            </Image>
            
        </div>
        
        
    </Container>
)

RPicture.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
    })
}

export default RPicture